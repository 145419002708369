import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import imgTitleRoesterei from "../images/page_title_roesterei.png"

const Roesterei = (props) => {
  return (
    <Layout>
      <Seo title="kaffeerösterei in hildesheim" />
      <section className="roesterei container">
        <div
          style={{
            position: "relative",
            paddingTop: `56.25%`,
            marginBottom: `15px`,
          }}
        >
          <iframe
            title="Von der Bohne zu Ihrem Kaffegenuss"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/hHaBQphjjH0?controls=0&amp;autoplay=1&amp;disablekb=0&amp;loop=1&amp;modestbranding=1&amp;mute=1&amp;rel=0&amp;controls=0"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullscreen
            style={{ position: "absolute", top: "0", left: "0" }}
          ></iframe>
        </div>
        <img
          className="page-title"
          src={imgTitleRoesterei}
          alt="unsere kaffee rösterei in hildesheim"
        />
        <GatsbyImage
          image={
            props.data.page_roesterei_certificate.childImageSharp
              .gatsbyImageData
          }
          className="page-certificate"
          alt="unsere kaffee rösterei in hildesheim"
        />
        <GatsbyImage
          image={
            props.data.page_roesterei_certificate_right.childImageSharp
              .gatsbyImageData
          }
          className="page-certificate-right"
          alt="unsere kaffee rösterei in hildesheim"
        />
        <p>
          bei uns wird im trommelröster sorgfältig und von hand geröstet. unser
          trommelröster steht unter dem dach des „süßesten fachwerkhauses der
          welt“, dem „zuckerhut“ im herzen hildesheims – ein bezauberndes
          ambiente und ein weiterer grund für soviel spaß am kaffeerösten. bei
          niedrigen temperaturen und ausreichend langen röstzeiten können unsere
          bohnen – unter ständiger beobachtung – ihr potenzial entwickeln, d.h.
          die einzigartigen aromen und der geschmack können sich optimal
          entfalten. wir beziehen unsere kaffeebohnen von kleinen plantagen aus
          verschiedenen anbauregionen. neben persönlichem kontakt ist uns die
          qualität, nachhaltiger anbau, fairer handel und transparenz besonders
          wichtig. für diese bedingungen treten wir als mitglied der deutschen
          röstergilde, gemeinsam mit anderen kleinen röstereien, ein. mit viel
          erfahrung und feingefühl werden bei uns leckere kaffees kreiert,
          frisch für den verkauf, für die kaffeebar oder für den versand.
        </p>

        <p className="content-center">
          als beispiel für transparente und nachhaltige produktionen sehen sie
          hier einen kurzen film der finca rosenheim aus peru!
        </p>
        <div
          style={{
            position: "relative",
            paddingTop: `56.25%`,
            marginBottom: `25px`,
          }}
        >
          <iframe
            title="finca rosenheim"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
            frameborder="0"
            src="https://www.youtube.com/embed/R0hp0iaEoOA?rel=0&amp;modestbranding=1"
            width="100%"
            height="100%"
            style={{ position: "absolute", top: "0", left: "0" }}
          ></iframe>
        </div>
        <div className="page-imagebox">
          <div className="page-imagebox-image">
            <GatsbyImage
              image={props.data.page_roesterei1.childImageSharp.gatsbyImageData}
              alt="unser laden im zuckerhut"
            />
          </div>
          <div className="page-imagebox-image">
            <GatsbyImage
              image={props.data.page_roesterei2.childImageSharp.gatsbyImageData}
              alt="unser laden im zuckerhut"
            />
          </div>
          <div className="page-imagebox-image">
            <GatsbyImage
              image={props.data.page_roesterei3.childImageSharp.gatsbyImageData}
              alt="unser laden im zuckerhut"
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    page_roesterei1: file(relativePath: { eq: "page_roesterei-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    page_roesterei2: file(relativePath: { eq: "page_roesterei-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    page_roesterei3: file(relativePath: { eq: "page_roesterei-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    page_roesterei_certificate: file(relativePath: { eq: "feinschmecker.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    page_roesterei_certificate_right: file(relativePath: { eq: "feinschmecker2022.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
  }
`

export default Roesterei
